import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { roundNumber } from 'application/utils/numbers';
import { Toast } from 'ui';

import GET_LAST_ANNOUNCES, {
  GetLastAnnouncesQuery,
  GetLastAnnouncesQueryVars,
} from '../../graphql/get-last-announces';

const useLastAnnounces = (sport?: string) => {
  const [announces, setAnnounces] = useState<
    {
      id: string;
      tournamentId: string;
      name: string;
      date: Date;
      description: string;
      opponent1: {
        flag: string;
        name: string;
        image: string;
        coeff: number;
        score: string;
      };
      opponent2: {
        flag: string;
        name: string;
        image: string;
        coeff: number;
        score: string;
      };
    }[]
  >([]);

  const { data, error, loading } = useQuery<
    GetLastAnnouncesQuery,
    GetLastAnnouncesQueryVars
  >(GET_LAST_ANNOUNCES, { variables: { sport }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getLastAnnounces.__typename === 'Error') {
      Toast.error(data.getLastAnnounces.error);
    }

    if (data?.getLastAnnounces.__typename === 'GetLastAnnounces') {
      setAnnounces(
        data.getLastAnnounces.announces.map((item) => ({
          id: item.id,
          tournamentId: item.tournamentId,
          name: item.name,
          date: new Date(item.date),
          description: item.description,
          opponent1: {
            flag: item.opponent1.country,
            name: item.opponent1.name,
            image: item.opponent1.image,
            score: item.opponent1.score,
            coeff: roundNumber(item.coef1, 2),
          },
          opponent2: {
            flag: item.opponent2.country,
            name: item.opponent2.name,
            image: item.opponent2.image,
            score: item.opponent2.score,
            coeff: roundNumber(item.coef2, 2),
          },
        }))
      );
    }
  }, [data, error]);

  return {
    loading,
    announces,
  };
};

export default useLastAnnounces;
