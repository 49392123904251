import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';

import { Toast } from 'ui';

import HowVote from './how-vote';
import GET_TRAINING_VIDEOS, {
  GetTrainingVideoQueryVars,
  GetTrainingVideosQuery,
} from '../../graphql/get-training-videos';

const HowVoteContainer = () => {
  const { data, error } = useQuery<
    GetTrainingVideosQuery,
    GetTrainingVideoQueryVars
  >(GET_TRAINING_VIDEOS);

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getTrainingVideos.__typename === 'Error') {
      Toast.error(data.getTrainingVideos.error);
    }
  }, [data, error]);

  if (data?.getTrainingVideos.__typename !== 'GetTrainingVideos') return null;

  return <HowVote video={data?.getTrainingVideos.videos} />;
};

export default HowVoteContainer;
