import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { InstagramIcon, TelegramIcon, YoutubeIcon } from 'ui/icons';

import css from './contacts.module.scss';

const Contacts: React.FC = () => {
  const { t } = useTranslation(['footer', 'help']);

  return (
    <div className={css.Root}>
      <ul className={css.Menu}>
        <li className={css.Item}>
          <Link href="/about">{t('footer:about_us')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/articles">{t('footer:articles')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/rules">{t('footer:platform_rules')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/rules-and-conditions-of-the-prediction-contest">
            {t('footer:prediction_contest_rules')}
          </Link>
        </li>
        <li className={css.Item}>
          <Link href="/help">{t('help:labels:help')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/job">{t('footer:vacancies')}</Link>
        </li>
        <li className={css.Item}>
          <Link href={`mailto:${webApp.supportEmail}`}>
            {t('footer:contact_us')}
          </Link>
        </li>
      </ul>

      <ul className={css.ListSocial}>
        <li className={css.ListSocialItem}>
          <a
            href="https://www.youtube.com/@eramma"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
        </li>
        <li className={css.ListSocialItem}>
          <a
            href="https://www.instagram.com/eramma_ru/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </li>
        <li className={css.ListSocialItem}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://t.me/votesport1" target="_blank">
            <TelegramIcon />
          </a>
        </li>
      </ul>
      <div className={css.Footer}>{t('footer:copyright')}</div>
    </div>
  );
};

export default Contacts;
