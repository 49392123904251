import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { errorNotification } from 'application/notification';
import { Toast } from 'ui';

import HotTournament from './hot-tournament';
import GET_HOT_TOURNAMENTS, {
  GetHotTournamentsQuery,
  GetHotTournamentsQueryVars,
} from '../../graphql/get-hot-tournaments';

const HotTournamentConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const [tournament, setTournament] = useState<string | null>(null);

  const { data, error } = useQuery<
    GetHotTournamentsQuery,
    GetHotTournamentsQueryVars
  >(GET_HOT_TOURNAMENTS, {
    ssr: false,
    nextFetchPolicy: 'network-only',
    variables: { sport },
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }
    if (data?.getHotTournaments.__typename === 'Error') {
      errorNotification(data.getHotTournaments.error);
    }

    if (
      data?.getHotTournaments.__typename === 'GetHotTournaments' &&
      data.getHotTournaments.tournaments.length > 0
    ) {
      const sorted = [...data.getHotTournaments.tournaments];
      sorted.sort((a, b) => {
        return (
          (b.name.includes('UFC') ? 1 : 0) - (a.name.includes('UFC') ? 1 : 0)
        );
      });

      setTournament(sorted[0].id);
    }
  }, [data, error]);

  if (data?.getHotTournaments.__typename !== 'GetHotTournaments') {
    return null;
  }

  if (data.getHotTournaments.tournaments.length === 0) {
    return null;
  }

  const selectedTournament = data.getHotTournaments.tournaments.find(
    (item) => item.id === tournament
  );

  if (!selectedTournament) {
    return null;
  }

  const sortedTournmants = [...data.getHotTournaments.tournaments];
  sortedTournmants.sort((a, b) => {
    return (b.name.includes('UFC') ? 1 : 0) - (a.name.includes('UFC') ? 1 : 0);
  });

  return (
    <HotTournament
      tournament={tournament!}
      tournaments={sortedTournmants.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
      date={new Date(selectedTournament.startDate)}
      events={selectedTournament.results}
      onTournamentChange={setTournament}
    />
  );
};

export default HotTournamentConnected;
