import { gql } from '@apollo/client';

export type OpponentModel = {
  id: string;
  image: string;
  name: string;
  score: string;
  rank: string;
  country: string;
  footballOpponent?: {
    oldRank: string | null;
    rank: string | null;
  };
  mmaOpponent: {
    countryFlag: string | null;
    rating: string | null;
    win: number | null;
    draw: number | null;
    lose: number | null;
  };
};

export const OPPONENET_FRAGMENT = gql`
  fragment OpponentFragment on EventOpponent {
    id
    name
    image
    score
    rank
    country
  }
`;
