import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { roundNumber } from 'application/utils/numbers';
import { Toast } from 'ui';

import GET_VOTE_LEDAERS, {
  GetVoteLeadersQuery,
  GetVoteLeadersQueryVars,
} from '../../../main/graphql/get-vote-leaders';

const useVoteLeaders = (period: string, sport?: string) => {
  const [leaders, setLeaders] = useState<
    {
      id: string;
      tournamentId: string;
      position: number;
      positionStatus: boolean | null;
      name: string;
      coeff1: string;
      coeff2: string;
      rating: string;
    }[]
  >([]);

  const { data, error, loading } = useQuery<
    GetVoteLeadersQuery,
    GetVoteLeadersQueryVars
  >(GET_VOTE_LEDAERS, {
    fetchPolicy: 'network-only',
    variables: { period, sport },
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getVoteLeaders.__typename === 'Error') {
      Toast.error(data.getVoteLeaders.error);
    }

    if (data?.getVoteLeaders.__typename === 'GetVoteLeaders') {
      setLeaders(
        data.getVoteLeaders.leaders.map((item) => ({
          id: item.id,
          tournamentId: item.tournamentId,
          position: item.position,
          positionStatus: item.positionStatus,
          name: item.name,
          coeff1: item.coef1,
          coeff2: item.coef2,
          rating: item.increase,
        }))
      );
    }
  }, [data, error]);

  return {
    loading,
    leaders,
  };
};

export default useVoteLeaders;
