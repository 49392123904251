import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { roundNumber } from 'application/utils/numbers';
import { Toast } from 'ui';

import GET_NEXT_EVENTS, {
  GetNextEventsQuery,
  GetNextEventsQueryVars,
  NextEventMmaOpponent,
} from '../../graphql/get-next-events';

const useNextEvents = (sport?: string) => {
  const [events, setEvents] = useState<{
    tournaments: {
      id: string;
      name: string;
      date: Date;
      sportType: string | null;
    }[];
    events: {
      id: string;
      tournamentId: string;
      startDate: string;
      opponent1: {
        id: string;
        name: string;
        country: string;
        score: string;
        image: string;
        coef: number;
        mmaOpponent: NextEventMmaOpponent;
      };
      opponent2: {
        id: string;
        name: string;
        country: string;
        score: string;
        image: string;
        coef: number;
        mmaOpponent: NextEventMmaOpponent;
      };
    }[];
  }>({ tournaments: [], events: [] });

  const { data, error, loading } = useQuery<
    GetNextEventsQuery,
    GetNextEventsQueryVars
  >(GET_NEXT_EVENTS, {
    variables: { sport },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getNextEvents.__typename === 'Error') {
      Toast.error(data.getNextEvents.error);
    }

    if (data?.getNextEvents.__typename === 'GetNextEvents') {
      setEvents({
        tournaments: data.getNextEvents.tournaments.map((item) => ({
          id: item.id,
          name: item.name,
          date: new Date(item.date),
          sportType: item.sportType,
        })),
        events: data.getNextEvents.events.map((item) => ({
          id: item.id,
          startDate: item.startDate,
          tournamentId: item.tournamentId,
          opponent1: { ...item.opponent1, coef: item.coef1 },
          opponent2: { ...item.opponent2, coef: item.coef2 },
        })),
      });
    }
  }, [data, error]);

  return {
    loading,
    events,
  };
};

export default useNextEvents;
