import { gql } from '@apollo/client';

import { OPPONENET_FRAGMENT } from 'application/graphql/opponent-type';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';
import {
  EventModel,
  OutcomeLiveInfoModel,
  OutcomeModel,
  OutcomePariInfoModel,
  ResultModel,
  ResultPariInfoModel,
  SubtournamentModel,
  TournamentModel,
} from '../tournament-types';

export type TournamentInfo = {
  tournament: TournamentModel;
  tournaments: { id: string; name: string }[];
  subtournaments: SubtournamentModel[];
  events: EventModel[];
  outcomes: OutcomeModel[];
  results: ResultModel[];
  drawIcon: string;
};

export interface GetTournamentInfoQuery {
  getTournamentInfo:
    | ({
        __typename: 'GetTournamentInfo';
      } & TournamentInfo)
    | GraphqlErrorType;

  getTournamentPariInfo:
    | {
        __typename: 'GetTournamentPariInfo';
        results: ResultPariInfoModel[];
        outcomes: OutcomePariInfoModel[];
      }
    | GraphqlErrorType;

  getTournamentLiveInfo:
    | {
        __typename: 'GetTournamentLiveInfo';
        results: ResultPariInfoModel[];
        outcomes: OutcomeLiveInfoModel[];
      }
    | GraphqlErrorType;
}

export interface GetTournamentInfoQueryVars {
  id: string;
  sort?: 'date' | 'volume';
}

const GET_TOURNAMENT_INFO = gql`
  ${OPPONENET_FRAGMENT}
  query GetTournamentInfo($id: ID!, $sort: String) {
    getTournamentInfo(id: $id, sort: $sort) {
      __typename
      ... on GetTournamentInfo {
        tournament {
          id
          name
          startDate
          description
          location
          sportType
        }
        drawIcon
        tournaments {
          id
          name
        }
        subtournaments {
          id
          name
        }
        events {
          id
          name
          endDate
          pariPosition
          score
          livePosition
          commentsCount
          extra1
          extra2
          firstPeriod
          secondPeriod
          phase
          opponent1 {
            footballOpponent {
              oldRank
              rank
            }
            ...OpponentFragment
            mmaOpponent {
              countryFlag
              rating
              win
              draw
              lose
            }
          }
          opponent2 {
            footballOpponent {
              oldRank
              rank
            }
            ...OpponentFragment
            mmaOpponent {
              countryFlag
              rating
              win
              draw
              lose
            }
          }
        }
        outcomes {
          id
          name
          win
          draw
          position
        }
        results {
          id
          tournamentId
          subtournamentId
          eventId
          name
          endDate
          main
          outcomes
        }
      }
      ... on Error {
        error
      }
    }
    getTournamentPariInfo(id: $id) {
      __typename
      ... on GetTournamentPariInfo {
        results {
          id
          amount
        }
        outcomes {
          id
          amount
          coeff
          percent
          selected
        }
      }
      ... on Error {
        error
      }
    }
    getTournamentLiveInfo(id: $id) {
      __typename
      ... on GetTournamentLiveInfo {
        results {
          id
          amount
        }
        outcomes {
          id
          amount
          coeff
          percent
          selected
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_TOURNAMENT_INFO;
