import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { formatDatetimeToFull } from 'application/utils/date';
import { Toast } from 'ui';

import GET_NEXT_TOURNAMENTS, {
  GetNextTournamentsQuery,
  GetNextTournamentsQueryVars,
} from '../../graphql/get-next-tournaments';

const useNextTournaments = (sport?: string) => {
  const [tournaments, setTournaments] = useState<
    {
      id: string;
      name: string;
      icon: string;
      time: string;
    }[]
  >([]);

  const { data, error, loading } = useQuery<
    GetNextTournamentsQuery,
    GetNextTournamentsQueryVars
  >(GET_NEXT_TOURNAMENTS, {
    variables: { sport },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getNextTournaments.__typename === 'Error') {
      Toast.error(data.getNextTournaments.error);
    }

    if (data?.getNextTournaments.__typename === 'GetNextTournaments') {
      setTournaments(
        data.getNextTournaments.tournaments.map((item) => ({
          id: item.id,
          name: item.name,
          icon: item.icon,
          time: formatDatetimeToFull(new Date(item.date)),
        }))
      );
    }
  }, [data, error]);

  return {
    loading,
    tournaments,
  };
};

export default useNextTournaments;
