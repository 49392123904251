import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { Toast } from 'ui';

import TournamentComments from './tournament-comments';
import GET_TOURNAMENTS_EVENTS_COMMENTS, {
  GetTournamentEventsCommentsQuery,
  GetTournamentEventsCommentsQueryVars,
} from '../../graphql/get-tournament-events-comments';

export const GENERAL_DISCUSSION_ID = 'general-discussion-id';

const TournamentCommentsContainer: React.FC<{ tournament: string }> = ({
  tournament,
}) => {
  const { t } = useTranslation(['tournaments']);
  const router = useRouter();
  const eventId = router.query.event || GENERAL_DISCUSSION_ID;

  const { data: eventsData, error: eventsError } = useQuery<
    GetTournamentEventsCommentsQuery,
    GetTournamentEventsCommentsQueryVars
  >(GET_TOURNAMENTS_EVENTS_COMMENTS, {
    variables: { id: tournament },
  });

  useEffect(() => {
    if (eventsError) {
      Toast.debugError(eventsError.message);
    }

    if (eventsData?.getTournamentEventsComments.__typename === 'Error') {
      Toast.error(eventsData.getTournamentEventsComments.error);
    }
  }, [eventsData, eventsError]);

  const selectEvent = (id: string) => {
    const newQuery: { event?: string } = {
      ...router.query,
      event: id,
    };

    if (id === GENERAL_DISCUSSION_ID) {
      delete newQuery.event;
    }

    void router.replace(
      { pathname: router.pathname, query: newQuery },
      undefined,
      { shallow: true }
    );
  };

  if (
    eventsData?.getTournamentEventsComments.__typename !==
    'GetTournamentEventsComments'
  ) {
    return null;
  }

  const events = [
    {
      id: GENERAL_DISCUSSION_ID,
      name: t('tournaments:general_discussion'),
      count: eventsData.getTournamentEventsComments.count,
    },
    ...eventsData.getTournamentEventsComments.events.map((event) => ({
      id: event.id,
      name: event.name,
      count: event.count,
    })),
  ];

  return (
    <TournamentComments
      tournament={tournament}
      events={events}
      eventId={eventId as string}
      selectEvent={selectEvent}
    />
  );
};

export default TournamentCommentsContainer;
